@import '../Shared/styles/colors.scss';

.clickable-header {
  text-decoration: underline;
}

.table-container.is-scrolling {
  .table {
    .player {
      box-shadow: 8px 0 6px -6px rgba(0, 0, 0, 0.3);
    }
  }
}

.table.is-custom-striped {
  .player {
    padding-left: 0;
    margin-top: 1px;
    min-width: 175px;
    max-width: 175px;
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;
    background-color: $white;
    position: absolute;
  }

  .player-span {
    min-width: 175px;
    max-width: 175px;
  }

  thead {
    tr th {
      white-space: nowrap;

      &.sortable {
        cursor: pointer;
        text-underline-offset: 4px;
        text-decoration: dotted underline;
      }
    }
  }

  tbody {
    tr td {
      height: 40px;
      overflow: hidden;
      text-overflow: ellipsis;
      white-space: nowrap;
    }

    tr:not(.is-selected):nth-child(even) {
      background-color: $white;
      .player {
        background-color: $white;
      }
    }

    tr:not(.is-selected):nth-child(odd) {
      background-color: $white-dark;

      .player {
        background-color: $white-dark;
      }
    }
  }
}

@media screen and (max-width: 768px) {
  .table.is-custom-striped {
    tbody {
      tr td {
        height: 32px;
      }
    }

    .player {
      min-width: 125px;
      max-width: 125px;
    }

    .player-span {
      min-width: 125px;
      max-width: 125px;
    }
  }
}
